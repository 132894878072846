module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-65599459-6","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/rwieruch/Websites/roadtoreact.com/node_modules/gatsby-remark-images","id":"ba1f9c43-e15c-5ef5-bb8b-f66996fbc3db","name":"gatsby-remark-images","version":"6.14.0","modulePath":"/home/rwieruch/Websites/roadtoreact.com/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1024,"withWebp":true,"linkImagesToOriginal":false,"showCaptions":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/rwieruch/Websites/roadtoreact.com/node_modules/gatsby-remark-autolink-headers","id":"d0cfca44-2c90-5b5c-9e6a-82c925de3300","name":"gatsby-remark-autolink-headers","version":"5.14.0","modulePath":"/home/rwieruch/Websites/roadtoreact.com/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"offsetY":100,"enableCustomId":true,"icon":"<svg height=\"0\" width=\"0\"></svg>"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/rwieruch/Websites/roadtoreact.com","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"withWebp":true,"linkImagesToOriginal":false,"showCaptions":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"enableCustomId":true,"icon":"<svg height=\"0\" width=\"0\"></svg>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.roadtoreact.com/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Road to React","short_name":"RoadToReact","start_url":"/","background_color":"#fff","theme_color":"#823EB7","display":"standalone","icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9db82c76a9aaf54925ac42d41f3d384c"},
    }]
